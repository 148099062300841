<template>
  <div
    class="card"
    style="
      width: 44vw;
      height: 44vh;
      margin: 2rem;
      background-color: unset;
      border: none;
    "
  >
    <template v-if="inverted === false">
      <div
        class="card-body"
        style="
          position: relative;
          margin: 0 24px 24px 0;
          padding: 1rem 0 0 1rem;
          background-color: #eef3f5;
        "
      >
        <img
          :src="`https://cms-demo.brandlead.nl/assets/${flower.main_image.id}`"
          :alt="flower.title"
          style="
            margin-bottom: -24px;
            margin-right: -24px;
            margin-left: 24px;
            padding-top: 24px;
            float: right;
          "
        />
        <div
          style="
            height: 100%;
            display: flex;
            place-content: center;
            flex-direction: column;
            text-align: left;
          "
        >
          <h3>{{ flower.title }}</h3>
          <router-link
            :to="{ name: 'Preview1Show', params: { id: flower.id } }"
            v-slot="{ href, navigate }"
            custom
          >
            <a :href="href" @click="navigate" style="color: inherit; text-decoration: inherit;">
              <p v-html="flower.intro + arrow" />
            </a>
          </router-link>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        class="card-body"
        style="
          position: relative;
          margin: 24px 0 0 24px;
          padding: 0 1rem 1rem 0;
          background-color: #f7f2e6;
        "
      >
        <img
          :src="`https://cms-demo.brandlead.nl/assets/${flower.main_image.id}`"
          :alt="flower.title"
          style="
            margin-top: -24px;
            margin-left: -24px;
            margin-right: 24px;
            float: left;
          "
        />
        <div
          style="
            height: 100%;
            display: flex;
            place-content: center;
            flex-direction: column;
            text-align: left;
          "
        >
          <h3>{{ flower.title }}</h3>
          <router-link
            :to="{ name: 'Preview1Show', params: { id: flower.id } }"
            v-slot="{ href, navigate }"
            custom
          >
            <a :href="href" @click="navigate" style="color: inherit; text-decoration: inherit;">
              <p v-html="flower.intro + arrow" />
            </a>
          </router-link>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { toRefs, defineProps } from "vue";

const props = defineProps({
  flower: Object,
  inverted: Boolean,
});

const { flower, inverted } = toRefs(props);

const arrow = '<span style="font-size: 1rem; margin: 0; padding: 0; margin-left: 1rem;">→</span>';
</script>
