<template>
  <div class="p-5 rounded" style="margin-top: 1rem">
    <div
      id="carousel"
      class="carousel slide"
      data-bs-ride="carousel"
      style="height: 80vh"
    >
      <div class="carousel-inner w-100 h-100">
        <template v-for="(flower, index) in flowers" :key="index">
          <div
            class="carousel-item w-100 h-100"
            :class="{ active: index === 0 }"
          >
            <div class="d-flex justify-content-center w-100 h-100">
              <img
                :src="`https://cms-demo.brandlead.nl/assets/${flower.main_image.id}`"
                class="d-block"
                style="
                  width: 100%;
                  height: auto;
                  object-fit: cover;
                  object-position: top;
                "
                :alt="flower.title"
              />
              <div
                class="position-absolute text-center rounded"
                style="
                  width: 24%;
                  right: -4px;
                  bottom: -4px;
                  padding: 1rem;
                  background-color: rgb(248, 249, 250, 0.75);
                "
              >
                <h3>
                  {{ flower.title }}
                </h3>
              </div>
              <div
                class="position-absolute rounded"
                style="
                  width: 32%;
                  left: -4px;
                  top: -4px;
                  padding: 1rem;
                  background-color: rgb(248, 249, 250, 0.75);
                "
              >
                <p v-html="flower.intro" />
              </div>
              <div
                class="position-absolute rounded"
                style="width: 32%; left: -4px; bottom: -4px; padding: 1rem"
              >
                <img
                  src="../../assets/logo-fleurop.svg"
                  style="height: 6rem"
                  alt="logo"
                />
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { Carousel } from "bootstrap";

let flowers = ref([]);
let carousel = ref(null);

async function LoadFlowers() {
  const response = await fetch("https://cms-demo.brandlead.nl/graphql", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer API_TOKEN_FOR_TESTING_VIEWER",
    },
    body: JSON.stringify({
      query: `
        {
            bloemen {
              id
              code
              title
              intro,
              main_image {
                  id
              }
            }
        }
        `,
    }),
  });

  return response.json();
}

onMounted(() => {
  var myCarousel = document.querySelector("#carousel");
  carousel.value = new Carousel(
      myCarousel,
      {
          interval: 5000
      }
  );

  carousel.value.cycle();
});

onMounted(async () => {
  const response = await LoadFlowers();

  flowers.value = response.data.bloemen.sort((a, b) =>
    a.title.localeCompare(b.title)
  );
});
</script>
