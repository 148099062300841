<template>
  <div class="p-5 rounded" style="display: flex; flex-direction: column; place-content: center; place-items: center;">
    <h1 style="margin-top: 4rem; margin-bottom: 3rem;">Bloemenregister</h1>
    <template v-for="(flower, index) in flowers" :key="index">
      <flower-card :flower="flowers[index]" :inverted="index % 2 === 0" />
    </template>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import FlowerCard from "../../components/FlowerCard.vue";

let flowers = ref([]);

async function LoadFlowers() {
  const response = await fetch("https://cms-demo.brandlead.nl/graphql", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer API_TOKEN_FOR_TESTING_VIEWER",
    },
    body: JSON.stringify({
      query: `
        {
            bloemen {
              id
              code
              title
              intro,
              main_image {
                  id
              }
            }
        }
        `,
    }),
  });

  return response.json();
}

onMounted(async () => {
  const response = await LoadFlowers();

  flowers.value = response.data.bloemen.sort((a, b) => a.title.localeCompare(b.title));
});
</script>
